import { BaseApiModel } from '@shared/models/base-api.model';
import { MoneyValueModel } from '@shared/models/money-value.model';
import { TEmployeeStatus } from '@shared/types';
import { EmployeeWalletModel } from '@shared/models/employee.wallet.model';

class EmployeeModel extends BaseApiModel {
    userId = '';
    firstname = '';
    lastname = '';
    staffNumber = '';
    email = '';
    activationDate?: number | null;
    entryDate?: number | null;
    exitDate?: number | null;
    seniority?: number;
    location?: string;
    deleted?: string;
    userDeletionDate?: number;
    companyId = '';
    status: TEmployeeStatus = null;
    budget: MoneyValueModel = new MoneyValueModel();
    budgetAvailable: MoneyValueModel = new MoneyValueModel();
    fringeBenefitsBudget: MoneyValueModel = new MoneyValueModel();
    reservedBudget: MoneyValueModel = new MoneyValueModel();
    wallet: EmployeeWalletModel = new EmployeeWalletModel();
}

class EmployeeCreateModel {
    email = '';
    firstname = '';
    lastname = '';
    staffNumber? = '';
    companyId = '';
    budget: MoneyValueModel = new MoneyValueModel();
    activationDate?: number | null;
    entryDate?: number | null;
    exitDate?: number | null;

    parseDates(entryDate: Date, exitDate: Date, activationDate: Date) {
        if (activationDate) this.activationDate = activationDate.getTime();
        if (entryDate) this.entryDate = entryDate.getTime();
        if (exitDate) this.exitDate = exitDate.getTime();
    }
}

export { EmployeeCreateModel, EmployeeModel };
