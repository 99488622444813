import { THistorySubtype, THistoryType } from '@shared/types';

class EmployeeHistoryModel {
    employeeId = '';
    context = '';
    type: THistoryType = 'default';
    subtype?: THistorySubtype;
    performedBy = new HistoryByUserModel();
    timestamp = Date.now();
    employeeEmail?: string;
    username?: string;
    valueBefore: { [key: string]: any } = {};
    valueAfter: { [key: string]: any } = {};
    comment?: string;
}

class HistoryByUserModel {
    userId = '';
    employeeId?: string;
    email = '';
    username = '';
    firstname = '';
    lastname?: string;
}

export { EmployeeHistoryModel, HistoryByUserModel };
